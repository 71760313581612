
import { Ref, ref, onMounted, ComputedRef, computed } from 'vue';
export default {
  setup() {
    const reactionBar: Ref<HTMLElement | null> = ref(null);
    const numberOfReactions: Ref<number> = ref(1);
    const widthCssRule: ComputedRef<string> = computed(() => `calc(45px * ${numberOfReactions.value})`);

    function handleReaction(reactionType: string): void {
      console.warn(`${reactionType} handler not yet set up`);
    };

    onMounted(() => {
      numberOfReactions.value = reactionBar.value?.children?.length ?? 1;
    });

    return {
      reactionBar,
      widthCssRule,
      handleReaction,
    }
  },
};
