<template>
	<div class="reaction-icon love">
		<!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
		<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
			<path style="fill:#F44336;" d="M364,48c-39.648,0-78.88,14.912-108,40.544C226.88,62.912,187.648,48,148,48
				C65.024,48,0,109.248,0,187.424c0,114.912,222.144,258.144,247.456,274.08C250.08,463.2,253.024,464,256,464
				c2.912,0,5.824-0.768,8.416-2.368C356.352,404.864,512,283.296,512,187.424C512,109.248,446.976,48,364,48z"/>
		</svg>
	</div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.love {
  &:hover {
    animation: heartbeat 260ms ease-in-out infinite;
  }
}

@keyframes heartbeat {
  from {
    transform: scale(1.2001);
  }
  50% {
    transform: scale(1.1501);
  }
  to {
    transform: scale(1.1501);
  }
}
</style>
