
import { defineComponent } from 'vue'
import { auth } from '@/firebase'

export default defineComponent({
  setup() {
    function logUserOut(): void {
      auth.signOut()
        .catch(err => console.error(err));
    }

    return {
      logUserOut,
    }
  },
});
