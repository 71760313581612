<template>
  <button
    :class="{
    'button': true,
    'm--undesired': undesired,
    }"
    :disabled="$attrs.disabled"
  >
    <slot />
  </button>
</template>

<script lang="ts">
export default {
  props: {
    undesired: {
      type: Boolean,
      default: false,
    },
  }
}
</script>

<style lang="scss" scoped>
.button {
  cursor: pointer;
  border: none;
  background: var(--primaryAlt);
  height: 48px;
  border-radius: 4px;
  padding: 0 20px;
  font-size: 18px;
  font-weight: 700;
  color: var(--white);
  letter-spacing: 0.5px;
  &.m {
    &--undesired {
      height: unset;
      background: transparent;
      color: var(--primaryAlt);
      font-weight: 600;
      font-size: 16px;
      text-decoration: underline;
      &:disabled {
        opacity: 0.6;
      }
    }
  }
  &:disabled {
    background: rgb(207, 207, 207);
    color: rgb(94, 94, 94);
  }
}
</style>
