<template>
	<div class="reaction-icon">
		<!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
		<svg title="delete" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
			<path style="fill:#FF485A;" d="M452.923,98.462h-98.462C354.462,44.081,310.38,0,256,0s-98.462,44.081-98.462,98.462H59.077
				c-13.598,0-24.615,11.018-24.615,24.615s11.018,24.615,24.615,24.615h9.846V448c0.059,35.328,28.672,63.941,64,64h246.154
				c35.328-0.059,63.941-28.672,64-64V147.692h9.846c13.598,0,24.615-11.018,24.615-24.615S466.521,98.462,452.923,98.462z M256,49.231
				c27.185,0,49.231,22.046,49.231,49.231h-98.462C206.769,71.276,228.815,49.231,256,49.231z M393.846,448
				c0,8.153-6.617,14.769-14.769,14.769H132.923c-8.153,0-14.769-6.617-14.769-14.769V147.692h275.692V448z"/>
			<g>
				<path style="fill:#FFBBC0;" d="M201.846,379.077c-13.598,0-24.615-11.018-24.615-24.615V256c0-13.598,11.018-24.615,24.615-24.615
					s24.615,11.018,24.615,24.615v98.462C226.462,368.059,215.444,379.077,201.846,379.077z"/>
				<path style="fill:#FFBBC0;" d="M310.154,379.077c-13.598,0-24.615-11.018-24.615-24.615V256c0-13.598,11.018-24.615,24.615-24.615
					c13.598,0,24.615,11.018,24.615,24.615v98.462C334.769,368.059,323.751,379.077,310.154,379.077z"/>
			</g>
		</svg>
	</div>
</template>