<template>
	<div class="reaction-icon">
		<!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
		<svg title="send" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
		<path style="fill:#4DBBEB;" d="M507.607,4.395c-4.242-4.245-10.61-5.551-16.177-3.32l-482,192.798
			c-5.516,2.205-9.209,7.458-9.42,13.394c-0.211,5.936,3.101,11.438,8.444,14.029l190.067,92.182l92.182,190.068
			c2.514,5.184,7.764,8.454,13.493,8.454c0.178,0,0.357-0.003,0.536-0.01c5.936-0.211,11.188-3.904,13.394-9.419L510.928,20.573
			C513.156,15.002,511.85,8.638,507.607,4.395z"/>
		<path style="fill:#2488FF;" d="M507.607,4.395L198.522,313.477l92.182,190.068c2.514,5.184,7.764,8.454,13.493,8.454
			c0.178,0,0.357-0.003,0.536-0.01c5.936-0.211,11.188-3.904,13.394-9.419L510.928,20.573C513.156,15.002,511.85,8.638,507.607,4.395
			L507.607,4.395z"/>
		</svg>
	</div>
</template>