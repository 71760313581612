<template>
  <section aria-label="Homepage of Basar Family Website" class="home o-page">
    <h1 class="home__heading o-heading">Welcome!</h1>
    <h2 class="home__subheading o-subheading">
      This is the new home of the Basar Family "Social Network"
    </h2>
    <p>
      In order to gain access to the site, you will first need to submit a
      request. This can be done by clicking the button below and filling out the
      short form.
    </p>
    <section class="home__actions">
      <BaseButton @click="$router.push('request-access')">
        Request Access
      </BaseButton>
    </section>
  </section>
</template>

<script lang="ts">
import BaseButton from '@/components/BaseButton.vue';

export default {
  components: {
    BaseButton,
  },
}
</script>

<style lang="scss" scoped>
.home {
  &__actions {
    margin-top: 5rem;
  }
}
</style>
