
import { defineComponent, reactive, onMounted, toRefs } from 'vue';
import BaseLogo from '@/components/BaseLogo.vue';
import { auth } from '@/firebase';

export default defineComponent({
  components: {
    BaseLogo,
  },

  setup() {
    const state = reactive({
      isLoggedIn: false,
      windowScrolled: false,
    });

    onMounted(() => {
      auth.onAuthStateChanged(user => {
        state.isLoggedIn = !!user;
      });
      state.windowScrolled = window.pageYOffset >= 25;
      window.onscroll = function (ev: any) {
        state.windowScrolled = window.pageYOffset >= 25;
      }
    });

    return {
      ...toRefs(state),
    }
  }
})
