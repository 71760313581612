<template>
	<div :class="{
		'header': true,
		'is--active': windowScrolled,
	}">
		<BaseLogo class="header__logo" />
		<ReactionLogOut
			v-if="isLoggedIn"
			class="header__logout"
		/>
	</div>
</template>

<script lang="ts">
import { defineComponent, reactive, onMounted, toRefs } from 'vue';
import BaseLogo from '@/components/BaseLogo.vue';
import { auth } from '@/firebase';

export default defineComponent({
  components: {
    BaseLogo,
  },

  setup() {
    const state = reactive({
      isLoggedIn: false,
      windowScrolled: false,
    });

    onMounted(() => {
      auth.onAuthStateChanged(user => {
        state.isLoggedIn = !!user;
      });
      state.windowScrolled = window.pageYOffset >= 25;
      window.onscroll = function (ev: any) {
        state.windowScrolled = window.pageYOffset >= 25;
      }
    });

    return {
      ...toRefs(state),
    }
  }
})
</script>

<style lang="scss" scoped>
.header {
  position: fixed;
  display: grid;
  grid-template: auto / auto auto;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid transparent;
  padding: 20px 50px;
  transition: background-color 500ms ease-in-out, border 500ms ease-in-out,
    box-shadow 650ms ease-in-out;
  @media (max-width: 992px) {
    padding: 20px;
  }
  &__logout {
    justify-self: end;
  }
  &.is {
    &--active {
      background: rgba(255, 255, 255, 0.9);
      border-bottom-color: #eee;
      box-shadow: 0 0 8px #eee;
    }
  }
}
</style>