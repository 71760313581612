import { auth } from '@/firebase';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { Rooms } from '../../models/Rooms';
import Home from '../views/Home.vue';

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		name: 'Home',
		component: Home,
		beforeEnter: (to, from, next) => {
			if (!!auth.currentUser) next({ name: 'Foyer' })
			else next();
		}
	},
	{
		path: '/about',
		name: 'About',
		component: () => import(/* webpackChunkName: 'About' */ '../views/About.vue'),
	},
	{
		path: '/request-access',
		name: 'RequestAccess',
		// route level code-splitting
		// this generates a separate chunk (RequestAccess.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "RequestAccess" */ '../views/RequestAccess.vue'),
		beforeEnter: (to, from, next) => {
			if (!!auth.currentUser) next({ name: 'About' })
			else next();
		}
	},
	{
		path: '/sign-up',
		name: 'SignUp',
		component: () => import(/* webpackChunkName: 'SignUp' */ '../views/Login.vue'),
		// beforeEnter: (to, from, next) => {
		// 	if (!JSON.parse(localStorage.getItem(`fmlDnrBsr`) ?? `{}`)?.arc) next ({ name: `RequestAccess` });
		// 	else next();
		// }
	},
	{
		path: '/the-foyer',
		name: 'Foyer',
		component: () => import(/* webpackChunkName: 'Foyer' */ '../views/Foyer.vue'),
		meta: {
			room: Rooms.Foyer,
			collectionName: 'theFoyer',
		},
	},
	{
		path: '/dining-room',
		name: 'DiningRoom',
		component: () => import(/* webpackChunkName: 'DiningRoom' */ '../views/Foyer.vue'),
			meta: {
				room: Rooms.Dining,
				collectionName: 'theDiningRoom',
			},
	}
	// {
	// 	path: '/bad-boys-table',
	// 	name: 'BadBoysTable',
	// 	component: () => import(/* webpackChunkName: 'BadBoysTable' */ '../views/Foyer.vue'),
	// 	meta: {
	// 		room: Rooms.BadBoysTable,
	// 		collectionName: 'badBoysTable',
	// 	},
	// },
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

router.beforeEach((to, from, next) => {
	if (!auth.currentUser && to.name !== 'SignUp' && to.name !== 'RequestAccess') next({ name: 'SignUp' })
	else next();
});

auth.onAuthStateChanged(user => {
	if (!user) router.push('sign-up');
})

export default router;
