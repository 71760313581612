<template>
	<section
		ref="reactionBar"
		class="reactions"
		:style="{
			'width': widthCssRule,
		}"
	>
		<ReactionLike @click="handleReaction('like')" />
		<ReactionDislike @click="handleReaction('dislike')" />
		<ReactionLove @click="handleReaction('love')" />
		<ReactionHappy @click="handleReaction('happy')" />
		<ReactionSad @click="handleReaction('sad')" />
		<ReactionCool @click="handleReaction('cool')" />
		<ReactionPug @click="handleReaction('pug')" />
		<ReactionGhost @click="handleReaction('ghost')" />
	</section>
</template>

<script lang="ts">
import { Ref, ref, onMounted, ComputedRef, computed } from 'vue';
export default {
  setup() {
    const reactionBar: Ref<HTMLElement | null> = ref(null);
    const numberOfReactions: Ref<number> = ref(1);
    const widthCssRule: ComputedRef<string> = computed(() => `calc(45px * ${numberOfReactions.value})`);

    function handleReaction(reactionType: string): void {
      console.warn(`${reactionType} handler not yet set up`);
    };

    onMounted(() => {
      numberOfReactions.value = reactionBar.value?.children?.length ?? 1;
    });

    return {
      reactionBar,
      widthCssRule,
      handleReaction,
    }
  },
};
</script>

<style lang="scss" scoped>
.reactions {
  display: flex;
  flex-flow: row nowrap;
  // only for grid layout
  justify-self: flex-start;
  align-items: center;
  justify-content: space-evenly;
  height: 44px;
  padding: 10px 20px;
  background: white;
  border-radius: 44px;
}
</style>