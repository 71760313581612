<template>
	<div class="reaction-icon">
		<!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
		<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
			<circle style="fill:#FFCA28;" cx="256" cy="256" r="256"/>
			<g>
				<path style="fill:#6D4C41;" d="M357.824,394.176c-4.096,0-8.192-1.568-11.328-4.672c-48.384-48.384-132.672-48.384-181.024,0
					c-6.24,6.24-16.384,6.24-22.624,0s-6.24-16.384,0-22.624C173.088,336.672,213.248,320,256,320s82.912,16.672,113.12,46.88
					c6.24,6.24,6.24,16.384,0,22.624C366.016,392.64,361.92,394.176,357.824,394.176z"/>
				<circle style="fill:#6D4C41;" cx="160" cy="192" r="32"/>
				<circle style="fill:#6D4C41;" cx="352" cy="192" r="32"/>
			</g>
		</svg>
	</div>
</template>

<script>
export default {};
</script>