// Reaction Icons
import BaseBackground from '@/components/BaseBackground.vue';
import ReactionBar from '@/components/Reactions/ReactionBar.vue';
import ReactionCool from '@/components/Reactions/ReactionCool.vue';
import ReactionDelete from '@/components/Reactions/ReactionDelete.vue';
import ReactionDislike from '@/components/Reactions/ReactionDislike.vue';
import ReactionGhost from '@/components/Reactions/ReactionGhost.vue';
import ReactionHappy from '@/components/Reactions/ReactionHappy.vue';
import ReactionLike from '@/components/Reactions/ReactionLike.vue';
import ReactionLogOut from '@/components/Reactions/ReactionLogOut.vue';
import ReactionLove from '@/components/Reactions/ReactionLove.vue';
import ReactionPug from '@/components/Reactions/ReactionPug.vue';
import ReactionSad from '@/components/Reactions/ReactionSad.vue';
import ReactionSend from '@/components/Reactions/ReactionSend.vue';
import { createApp } from 'vue';
import App from './App.vue';
import { auth } from './firebase';
import router from './router';
import store from './store';


// @ts-expect-error
let app;
auth.onAuthStateChanged(() => {
	// @ts-expect-error
	if (!app) {
		app = createApp(App);
		app.component('ReactionHappy', ReactionHappy);
		app.component('ReactionCool', ReactionCool);
		app.component('ReactionDislike', ReactionDislike);
		app.component('ReactionGhost', ReactionGhost);
		app.component('ReactionLike', ReactionLike);
		app.component('ReactionLove', ReactionLove);
		app.component('ReactionPug', ReactionPug);
		app.component('ReactionSad', ReactionSad);
		app.component('ReactionBar', ReactionBar);
		app.component('ReactionSend', ReactionSend);
		app.component('ReactionLogOut', ReactionLogOut);
		app.component('BaseBackground', BaseBackground);
		app.component('ReactionDelete', ReactionDelete);
		app
			.use(store)
			.use(router)
			.mount('#app');
	}
});

// createApp(App)
//   .use(store)
//   .use(router)
//   // .use(firestorePlugin)
//   .mount('#app');
