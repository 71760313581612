<template>
	<div class="reaction-icon dislike">
			<!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
			<svg title="dislike" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
				viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
			<path style="fill:#90CAF9;" d="M448,304h-80c-26.464,0-48,21.536-48,48v64c0,44.096-35.904,80-80,80c-26.464,0-48-21.536-48-48
				v-44.544c0-20.288-5.504-40.224-15.968-57.632l-11.104-18.496c-8.64-14.4-24.384-23.328-41.152-23.328H112c-8.832,0-16-7.168-16-16
				V80c0-5.632,2.944-10.848,7.776-13.728l60.992-36.608C179.68,20.704,196.736,16,214.144,16h197.984
				c16.832,0,33.344,6.848,45.248,18.752l35.872,35.872C505.152,82.528,512,99.04,512,115.872V240C512,275.296,483.296,304,448,304z"/>
			<path style="fill:#1976D2;" d="M112,304H16c-8.832,0-16-7.168-16-16V32c0-8.832,7.168-16,16-16h96c8.832,0,16,7.168,16,16v256
				C128,296.832,120.832,304,112,304z"/>
		</svg>
	</div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.dislike {
  &:hover {
    animation: hoverDislike 180ms ease-in-out;
  }
}

@keyframes hoverDislike {
  from {
    transform: rotate(-20deg);
  }
  to {
    transform: rotate (25deg);
  }
}
</style>