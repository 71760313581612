<template>
	<div class="reaction-icon">
		<!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
		<svg
			version="1.1"
			title="happy"
			id="Capa_1"
			xmlns="http://www.w3.org/2000/svg"
			xmlns:xlink="http://www.w3.org/1999/xlink"
			x="0px"
			y="0px"
			viewBox="0 0 512 512"
			style="enable-background:new 0 0 512 512;"
			xml:space="preserve"
		>
			<circle style="fill:#FFCA28;" cx="256" cy="256" r="256" />
			<g>
				<circle style="fill:#6D4C41;" cx="160" cy="192" r="32" />
				<circle style="fill:#6D4C41;" cx="352" cy="192" r="32" />
				<path style="fill:#6D4C41;" d="M256,416c-42.752,0-82.912-16.672-113.152-46.88c-6.24-6.24-6.24-16.384,0-22.624
					s16.384-6.24,22.624,0c48.352,48.384,132.64,48.384,181.024,0c6.24-6.24,16.384-6.24,22.624,0s6.24,16.384,0,22.624
					C338.912,399.328,298.752,416,256,416z" />
			</g>
		</svg>

	</div>
</template>

<script>
export default {};
</script>