
import { onMounted, Ref, ref, defineComponent } from 'vue';
import BaseHeader from '@/components/BaseHeader.vue';
import { auth } from './firebase';
export default defineComponent({
  components: {
    BaseHeader,
  },

  setup() {

    function displayConsoleWarning(): void {
      console.warn(`
%cWARNING!!!
%cThis is a browser feature intended for developers.
If someone told you to copy & paste something here
to enable features or "hack" this site IT IS A SCAM.
Doing so may allow them to gain access to your system.
      `, `color: red; font-weight: 700; font-size: 22px;`, `color: red; font-size: 20px;`);
    }

    onMounted(() => {
      displayConsoleWarning();
    });

    return {}
  }
});
