<template>
	<div class="reaction-icon like">
		<!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
		<svg title="like" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
			<path style="fill:#90CAF9;" d="M448,207.984h-80c-26.464,0-48-21.536-48-48v-64c0-44.096-35.904-80-80-80c-26.464,0-48,21.536-48,48
				v44.544c0,20.288-5.504,40.224-15.968,57.632l-11.104,18.496c-8.64,14.4-24.384,23.328-41.152,23.328H112c-8.832,0-16,7.168-16,16
				v208c0,5.632,2.944,10.848,7.776,13.728l60.992,36.608c14.912,8.96,32,13.696,49.376,13.696h197.984
				c16.832,0,33.344-6.848,45.248-18.752l35.872-35.872C505.152,429.456,512,412.944,512,396.112V271.984
				C512,236.688,483.296,207.984,448,207.984z"/>
			<path style="fill:#1976D2;" d="M112,207.984H16c-8.832,0-16,7.168-16,16v256c0,8.832,7.168,16,16,16h96c8.832,0,16-7.168,16-16v-256
				C128,215.152,120.832,207.984,112,207.984z"/>
		</svg>
	</div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.like {
  &:hover {
    animation: hoverLike 180ms ease-in-out;
  }
}

@keyframes hoverLike {
  from {
    transform: rotate(-20deg);
  }
  to {
    transform: rotate (25deg);
  }
}
</style>
