<template>
  <div class="app">
    <BaseHeader />
    <main class="app__content">
      <BaseBackground />
      <router-view :key="$route.fullPath" />
    </main>
  </div>
</template>

<script lang="ts">
import { onMounted, Ref, ref, defineComponent } from 'vue';
import BaseHeader from '@/components/BaseHeader.vue';
import { auth } from './firebase';
export default defineComponent({
  components: {
    BaseHeader,
  },

  setup() {

    function displayConsoleWarning(): void {
      console.warn(`
%cWARNING!!!
%cThis is a browser feature intended for developers.
If someone told you to copy & paste something here
to enable features or "hack" this site IT IS A SCAM.
Doing so may allow them to gain access to your system.
      `, `color: red; font-weight: 700; font-size: 22px;`, `color: red; font-size: 20px;`);
    }

    onMounted(() => {
      displayConsoleWarning();
    });

    return {}
  }
});
</script>

<style lang="scss">
:root {
  font-size: 10px;
  --fontCopy: "Montserrat", sans-serif;
  --fontHeading: "Playfair Display", serif;
  --primary: #0b3954;
  --primaryAlt: #3454d1;
  --accent: #e0ff4f;
  --warn: #ff6663;
  --secondary: #bfd7ea;
  --white: #fefffe;
  --black: #333333;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: var(--fontCopy);
  // color: var(--white);
  color: var(--black);
  line-height: 1.5;
}

body {
  &::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    // background: linear-gradient(-45deg, var(--warn) 5%, var(--accent));
    // background: linear-gradient(-45deg, var(--warn) 5%, var(--accent));
    // background: linear-gradient(-45deg, var(--primary), var(--warn) 130%);
    opacity: 0.97;
  }
}

#app,
.app {
  position: relative;
  height: 100%;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

.app {
  display: grid;
  // justify-content: center;
  height: 100%;
  &__content {
    padding: 10rem 1.5rem;
  }
  &__logout {
    position: fixed;
    top: 10px;
    right: 10px;
    display: grid;
    place-items: center;
    height: 60px;
    width: 60px;
    border-radius: 100%;
    background: var(--white);
    box-shadow: 1px 1px 8px var(--grey, #eee);
    transition: transform 150ms ease-in-out;
    z-index: 100;
    &:hover {
      transform: scale(1.20001);
    }
  }
}

.o {
  &-heading {
    margin-top: 0;
    font-family: var(--fontHeading);
    font-size: 4rem;
    font-variation-settings: "wght" 750;
    color: var(--primaryAlt);
    letter-spacing: 1px;
  }
  &-subheading {
    font-size: 2.2rem;
  }
  &-copy {
    font-family: var(--fontCopy);
    font-size: 1.6rem;
  }
  &-page {
    max-width: 695px;
  }
}

p {
  @extend .o-copy;
}

// Reaction icon styling
.reaction-icon {
  cursor: pointer;
  display: inline-flex;
  height: 30px;
  width: 30px;
  transition: transform 150ms ease-in-out;
  &:hover {
    transform: scale(1.20001);
  }
}

a {
  &:link,
  &:visited {
    color: var(--primaryAlt);
    font-weight: 600;
  }
}

svg {
  width: 100%;
}
</style>
