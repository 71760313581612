<template>
	<div class="reaction-icon">
		<!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
		<svg title="ghost" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
			<path style="fill:#CFD8DC;" d="M504.026,482.144C415.482,430.656,415.962,305.472,415.994,304V160c0-88.224-71.776-160-160-160
				s-160,71.776-160,160v144c0,0.096,0,0.16,0,0.256c0,1.248,0.832,126.208-88.032,177.92c-6.24,3.648-9.28,11.04-7.392,18.016
				C2.426,507.168,8.762,512,15.994,512h67.68c10.176,0,20.16-1.92,29.728-5.728l44.768-17.952c11.52-4.576,24.192-4.544,35.648,0
				l32.48,12.992c19.168,7.68,40.256,7.68,59.392,0l32.48-12.992c11.456-4.544,24.128-4.608,35.648,0l44.768,17.92
				c9.504,3.808,19.488,5.76,29.696,5.76h67.712c7.232,0,13.568-4.832,15.424-11.84C513.338,493.184,510.266,485.824,504.026,482.144z"
				/>
			<g>
				<path style="fill:#37474F;" d="M303.994,224c-26.464,0-48-21.536-48-48s21.536-48,48-48s48,21.536,48,48S330.458,224,303.994,224z
					M303.994,160c-8.8,0-16,7.168-16,16s7.2,16,16,16s16-7.168,16-16S312.794,160,303.994,160z"/>
				<path style="fill:#37474F;" d="M176.634,192c-8.832,0-16.16-7.168-16.16-16s7.008-16,15.84-16h0.32c8.832,0,16,7.168,16,16
					S185.466,192,176.634,192z"/>
			</g>
			<path style="fill:#D32F2F;" d="M303.994,256h-96c-8.832,0-16,7.168-16,16v80c0,35.296,28.704,64,64,64s64-28.704,64-64v-80
				C319.994,263.168,312.826,256,303.994,256z"/>
			<path style="fill:#37474F;" d="M335.994,288h-160c-8.832,0-16-7.168-16-16s7.168-16,16-16h160c8.832,0,16,7.168,16,16
				S344.826,288,335.994,288z"/>
		</svg>

	</div>
</template>

<script>
export default {};
</script>