import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

export const fireConfig = {
	apiKey: 'AIzaSyCg4KTvI8I1VVfWb_If-oRVLz9RVm5zdPo',
	authDomain: 'basar-dev.firebaseapp.com',
	databaseURL: 'https://basar-dev.firebaseio.com',
	projectId: 'basar-dev',
	storageBucket: 'basar-dev.appspot.com',
	messagingSenderId: '236217248199',
	appId: '1:236217248199:web:0376f917c6851d345391e2',
	measurementId: 'G-G0Q05H3TJF',
};
firebase.initializeApp(fireConfig);

/** Utils */
export const db = firebase.firestore();
export const dbUtils = firebase.firestore;
export const auth = firebase.auth();
export const functions = firebase.functions();

/** collections */
export const publicPosts = db.collection('publicPosts');
