<template>
	<div class="reaction-icon">
		<!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
		<svg title="cool" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
			<circle style="fill:#FFCA28;" cx="256" cy="256" r="256"/>
			<path style="fill:#263238;" d="M351.68,128.32h-192c-44.096,0-80,35.904-80,80s35.904,80,80,80c24.128,0,46.72-10.656,61.952-29.216
				c0.384-0.48,0.768-0.992,1.12-1.536l32.928-40.032l32.896,40.032c0.352,0.544,0.704,1.056,1.12,1.568
				c15.296,18.56,37.856,29.216,61.984,29.216c44.128,0,80-35.904,80-80S395.776,128.32,351.68,128.32z"/>
			<path style="fill:#6D4C41;" d="M255.68,416.32c-8.832,0-16-7.168-16-16s7.168-16,16-16c34.4,0,66.496-13.312,90.368-37.504
				c6.208-6.24,16.32-6.336,22.624-0.128c6.272,6.176,6.336,16.32,0.128,22.624C338.88,399.648,298.688,416.32,255.68,416.32z"/>
		</svg>
	</div>
</template>

<script>
export default {};
</script>